<template>
	<div class="" v-if="dataReady">
		<div id="filters" class="container">
			<div class="row">
				<div class="col">
					<h6> Growareas </h6>
					<MultiSelect 
						v-model="selectedGAs"
						:options="GAoptions[selectedZone]"
						:multiple="true"
						:searchable="true"
						:close-on-select="false"
						:show-labels="false"
						placeholder="Filter Growareas"
					></MultiSelect>
				</div>
				<div class="col">
					<h6> Zone </h6>
					<v-select
						:clearable="false"
						:searchable="false"
						:options="zoneOptions"
						v-model="selectedZone"
					/>
				</div>
			</div>
		</div>

		<div class="batchlist scroll">
			<div
				class="batchcard box-shadow-1"
				v-for="(t, tkey) in growareas[selectedZone]"
				:key="tkey"
				v-show="selectedGAs.length > 0 ? selectedGAs.includes(t.growarea) : true "
			>
				<b-card-header>
					<h5> {{ t.growarea }} [{{ batches[t.growarea] != undefined ? batches[t.growarea].qtySum : 0 }}/{{ t.capacity }}] </h5>
				</b-card-header>

				<b-card-body class="batchcard__body">
					<table style="width: 90%; margin-left: auto; margin-right: auto;" 
						class="table"
						v-if="batches[t.growarea] != undefined"
					>
						<thead>
							<tr>
								<th> </th>
								<th> Variant </th>
								<th> Qty. </th>
								<th> Age </th>
								<th> Next Op. </th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(batchE, ekey) in batches[t.growarea].toEndBatches" :key="ekey" :class="{ 'table__archived' : batchE.archived != undefined}">
								<td v-if="ifDelayed(batchE.plannedEnd)" class="batchcard__delayed"> 
									&#11044;
								</td>
								<td v-else class="batchcard__toend"> 
									&#11044;
								</td>
								<td>
									{{ batchE.variant }}
								</td>
								<td>
									{{ batchE.quantity }}
								</td>
								<td>
									{{ batchE.plannedStart | batchAge }}
								</td>
								<td>
									{{ batchE.plannedEnd | formatDate }}
								</td>
							</tr>
						</tbody>
						<tbody>
							<tr v-for="(batchS, skey) in batches[t.growarea].toStartBatches" :key="skey" :class="{ 'table__archived' : batchS.archived != undefined}">
								<td v-if="ifDelayed(batchS.plannedStart)" class="batchcard__delayed"> 
									&#11044;
								</td>
								<td v-else class="batchcard__tostart">
									&#11044;
								</td>
								<td>
									{{ batchS.variant }}
								</td>
								<td>
									{{ batchS.quantity }}
								</td>
								<td>
									{{ batchS.plannedStart | batchAge }}
								</td>
								<td>
									{{ batchS.plannedStart | formatDate }}
								</td>
							</tr>
						</tbody>
					</table>
					<p v-else> Empty </p>
				</b-card-body>

				<b-card-footer class="batchcard__footer">
					<b-button variant="secondary" v-on:click="openBatchform(t.growarea, t.stage)">Create Batch</b-button>
					<b-button v-show="batches[t.growarea] != undefined" variant="primary" v-on:click="viewDetailed(t.growarea, tkey)">Update</b-button>
				</b-card-footer>

			</div>
		</div>

		<b-modal
			id="detailed-view"
			class="modal-update"
			size="lg"
			:hide-footer="true"
		>
			<template #modal-title v-if="selectedGA.idx != undefined">
				<h5> {{ selectedGA.gaId }} [{{ batches[selectedGA.gaId] ? batches[selectedGA.gaId].qtySum : 0 }}/{{ growareas[selectedZone][selectedGA.idx].capacity }}] </h5>
			</template>

			<div class="accordion" role="tablist">
				<div v-if="selectedGA.idx != undefined">
					<b-button block v-b-toggle.accordion-1 variant="danger"> {{ batches[selectedGA.gaId].toEndBatches.length }} batches to End</b-button>
					<b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
						<b-form @submit="submitUpdate('end')">
							<table style="width: 90%; margin-left: auto; margin-right: auto;" class="table">
								<thead>
									<tr>
										<th> Variant </th>
										<th> Qty. </th>
										<th> Age </th>
										<th> End Date </th>
										<th> Select </th>
										<!-- <th> Archive </th> -->
									</tr>
								</thead>
								<tbody>
									<tr v-for="(batchED, edkey) in batches[selectedGA.gaId].toEndBatches" :key="edkey" :class="{ 'table__archived' : batchED.archived != undefined }">
										<td>
											{{ batchED.variant }}
										</td>
										<td>
											<b-input
												:number="true"
												v-model="updateBatches.end[batchED._id].quantity"
												type="number"
												:min="batchED.quantity == 0 ? 0 : 1"
												:max="batchED.quantity"
												style="width: 80px; display: inline;"
												required
											/> of {{ batchED.quantity }}
										</td>
										<td>
											{{ batchED.completedStart | batchAge }}
										</td>
										<td>
											{{ batchED.plannedEnd | formatDate }}
										</td>
										<td>
											<b-check v-on:input="updateBatchselect($event, 'end', batchED._id)" />
										</td>
										<!-- <td v-if="!batchUpdate">
											<b-button v-if="batchED.archived == undefined" variant="danger" v-on:click="archiveBatch(batchED, batches[selectedGA.gaId].toEndBatches, edkey)"> <i class="fas fa-trash"></i> </b-button>
											<b-button v-else variant="warning" v-on:click="undoArchiveBatch(batchED, batches[selectedGA.gaId].toEndBatches, edkey)"> <i class="fas fa-undo"></i> </b-button>
										</td>
										<td v-else>
											...
										</td> -->
									</tr>
									<tr v-if="batches[selectedGA.gaId].toEndBatches.length == 0">
										<td colspan="5" style="text-align: center;"> Empty </td>
									</tr>
								</tbody>
							</table>
							<hr />
							<div class="submission_footer">
								<p class="error"> {{ updateError }} </p>
								<b-button type="submit" variant="danger">End Batches</b-button>
							</div>
						</b-form>
					</b-collapse>
				</div>
				
				<div v-if="selectedGA.idx != undefined" :set="startBatches = batches[selectedGA.gaId].toStartBatches.filter((b) => b.archived == undefined)">
					<b-button block v-b-toggle.accordion-2 variant="primary"> {{ startBatches.length }} batches to Start</b-button>
					<b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel" visible>
						<b-form @submit="submitUpdate('start')">
							<table style="width: 90%; margin-left: auto; margin-right: auto;" class="table">
								<thead>
									<tr>
										<th> Variant </th>
										<th> Qty. </th>
										<th> Age </th>
										<th> Start Date </th>
										<th> Select </th>
										<th> Archive </th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(batchSD, sdkey) in startBatches" :key="sdkey">
										<td>
											{{ batchSD.variant }}
										</td>
										<td>
											<b-input
												:number="true"
												v-model="updateBatches.start[batchSD._id].quantity"
												type="number"
												:min="batchSD.quantity == 0 ? 0 : 1"
												:max="batchSD.quantity"
												style="width: 80px; display: inline;"
												:disabled="batchSD.archived != undefined"
												required
											/> of {{ batchSD.quantity }}
										</td>
										<td>
											{{ batchSD.plannedStart | batchAge }}
										</td>
										<td>
											{{ batchSD.plannedStart | formatDate }}
										</td>
										<td>
											<b-check :disabled="batchSD.archived != undefined" v-on:input="updateBatchselect($event, 'start', batchSD._id)" />
										</td>
										<td v-if="!batchUpdate">
											<b-button variant="danger" v-on:click="archiveBatch(batchSD, batches[selectedGA.gaId].toStartBatches, sdkey)"> <i class="fas fa-trash"></i> </b-button>
										</td>
										<td v-else>
											...
										</td>
									</tr>
									<tr v-if="startBatches.length == 0">
										<td colspan="6" style="text-align: center;"> Empty </td>
									</tr>
								</tbody>
							</table>
							<hr />
							<div class="submission_footer">
								<p class="error"> {{ updateError }} </p>
								<b-button type="submit" variant="primary">Start Batches</b-button>
							</div>
						</b-form>
					</b-collapse>

				<div v-if="selectedGA.idx != undefined" :set="archivedBatches = batches[selectedGA.gaId].toStartBatches.filter((b) => b.archived != undefined)">
					<b-button block v-b-toggle.accordion-3 variant="warning"> {{ archivedBatches.length }} batches archived</b-button>
					<b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
						<table style="width: 90%; margin-left: auto; margin-right: auto;" class="table">
							<thead>
								<tr>
									<th> Variant </th>
									<th> Qty. </th>
									<th> Age </th>
									<th> Start Date </th>
									<th> Archive </th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(batchSD, sdkey) in archivedBatches" :key="sdkey" :class="{ 'table__archived' : batchSD.archived != undefined}">
									<td>
										{{ batchSD.variant }}
									</td>
									<td>
										{{ batchSD.quantity }}
									</td>
									<td>
										{{ batchSD.plannedStart | batchAge }}
									</td>
									<td>
										{{ batchSD.plannedStart | formatDate }}
									</td>
									<td v-if="!batchUpdate">
										<b-button variant="warning" v-on:click="undoArchiveBatch(batchSD, batches[selectedGA.gaId].toStartBatches, sdkey)"> <i class="fas fa-undo"></i> </b-button>
									</td>
									<td v-else>
										...
									</td>
								</tr>
								<tr v-if="archivedBatches.length == 0">
										<td colspan="5" style="text-align: center;"> Empty </td>
								</tr>
							</tbody>
						</table>
					</b-collapse>
				</div>
				</div>
			</div>
		</b-modal>

		<b-modal
			id="batch-creation"
			title="Create new Batch"
			size="lg"
			:hide-footer="true"
		>
			<b-form class="batchform-modal" @submit="submitBatch">
				<div class="row">
					<div class="col">
							<b-form-group
								label-cols-sm="4"
								label-cols-lg="3"
								label="Stage"
								label-for="stage"
							>
								<b-input
									type='text'
									v-model="createform.stage"
									disabled
								>
								</b-input>
							</b-form-group>
							<b-form-group
								label-cols-sm="4"
								label-cols-lg="3"
								label="Rack ID"
								label-for="rack-location"
							>
								<b-input
									type='text'
									v-model="createform.growarea"
									disabled
								>
								</b-input>
							</b-form-group>
					</div>
					<div class="col">
						<b-form-group
							label-cols-sm="4"
							label-cols-lg="3"
							label="Start Date:"
							label-for="sdate"
						>
							<b-form-input id="sdate" type="date" :max="maxDate" @input="updateEndDate" v-model="createform.startdate" required />
						</b-form-group>
						<b-form-group
							label-cols-sm="4"
							label-cols-lg="3"
							label="Duration (days):"
							label-for="ndays"
						>
							<b-form-input id="ndays" type="number" @input="updateEndDate" v-model="createform.ndays" required>
							</b-form-input>
						</b-form-group>
						<b-form-group
							label-cols-sm="4"
							label-cols-lg="3"
							label="End Date:"
							label-for="edate"
						>
							<b-form-input id="edate" type="date" v-model="createform.enddate" disabled />
						</b-form-group>
					</div>
				</div>

				<div>
					<table class="table"> 
						<thead>
							<tr>
								<th> # </th>
								<th> Variant </th>
								<th> Qty. </th>
								<th> Action </th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(batchCRT, crtkey) in createform.batches" :key="crtkey">
								<td>
									{{ crtkey }}
								</td>
								<td>
									<v-select
										id="variant"
										style="min-width: 200px"
										:clearable="false"
										:searchable="true"
										:options="variants"
										:reduce="option => option.value"
										v-model="batchCRT.variant"
									/>
								</td>
								<td>
									<b-form-input id="qty" style="width: 80px" :number="true" type="number" v-model="batchCRT.quantity" min="1" required />
								</td>
								<td>
									<b-button variant="danger" v-on:click="removeBatchform(crtkey)">X</b-button>	
								</td>
							</tr>
							<tr>
								<td colspan="4" style="text-align: center;">
									<b-button variant="warning" v-on:click="addBatchform">Add Batch</b-button>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<hr />
				<div class="submission_footer">
					<p class="error"> {{ createError }} </p>
					<b-button type="submit" variant="primary">Submit</b-button>
				</div>
			</b-form>
		</b-modal>
	</div>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
	name: "Crops",
	components: { },
	props: ["farm", "zone", "growarea"],
	filters: {
		formatDate: function (value) {
			if (value) {
				return moment(String(value)).format("DD-MM-YYYY");
			}
		},
		formatTime: function (value) {
			if (value) {
				return moment(String(value)).format("DD-MM-YYYY hh:mm");
			}
		},
		batchAge: function (startDate) {
			let a = moment();
			let b = moment(startDate);
			return a.diff(b, "days");
		}
	},
	watch: {
		farm: function(newVal) {
			this.selectedFarm = newVal;
			this.selectedZone = null;
			this.dataReady = false;
			this.getGrowAreas(false);
		},
		selectedZone: function(newVal) {
			this.selectedZone = newVal;
			this.$router.replace({
				name: "crops",
				params: { farm: this.farm },
				query: { zone: this.selectedZone }
			});
			this.selectedGAs = [];
			this.dataReady = false;
			this.getGrowAreas(true);
		}
	},
	data() {
		return {
			maxDate: moment().add(28, "days").format("YYYY-MM-DD"),
			dataReady: false,
			batchUpdate: false,
			selectedFarm: this.farm,
			selectedZone: this.zone,
			selectedGAs: [],
			zoneOptions: [],
			GAoptions: [],
			stages: [],
			growareas: {},
			batches: {},
			variants: [],
			growthLoc: {value: undefined, capacity: undefined},
			createform: {
				stage: '',
				growarea: "",
				startdate: undefined,
				enddate: undefined,
				ndays: 1,
				batches: [{variant: "", quantity: 1}]
			},
			selectedGA: {gaId: undefined, idx: undefined},
			updateBatches: {start: [], end: []},
			updateError: "",
			createError: ""
		}
	},
	mounted(){
		this.getGrowAreas(false);
		this.getVariants();
	},
	methods: {
		ifDelayed(date){
			let a = moment();
			let b = moment(date);
			return b < a;
		},
		getVariants(){
            this.variants = null;
			axios.get(`/${this.farm}/variantManagement/variants`)
			.then((res) => {
				let avariants = res.data.active;
				avariants = avariants.map((v) => {
					v.label = `${v.code || ""} ${v.colour} ${v.name || ""} ${v.group} - ${v.source}`;
					v.value = `${v.code || ""} ${v.colour} ${v.name || ""} ${v.group}`;
					return v;
				});
				this.variants = avariants;
			})
			.catch((error) => {
				console.error(error);
			})
        },
		getGrowAreas(rfsh){
			axios
				.get(`/${this.selectedFarm}/growareas`, { })
				.then((response) => {					
					this.growareas = response.data;
					if(!rfsh){
						this.GAoptions = [];
						for(var zone in this.growareas){
							this.GAoptions[zone] = [];
							for(var ga in this.growareas[zone]){
								this.GAoptions[zone].push(this.growareas[zone][ga].growarea);
							}
						}
						this.zoneOptions = Object.keys(response.data);
						this.selectedZone = this.zone || this.zoneOptions[0];
					}
					this.getBatches();
				})
				.catch((e) => {
					console.log(e);
				});
		},
		getBatches(){
			axios
				.get(`/${this.selectedFarm}/plantbatches`, { params: {zone: this.selectedZone } })
				.then((response) => {
					this.batches = response.data;
					this.dataReady = true;
					console.log(this.batches);

					// If growarea is in query, view the growarea in detail
					this.$nextTick(() => {
						if(this.growarea != undefined){
							let idx = this.growareas[this.selectedZone].findIndex((ga) => ga.growarea == this.growarea);
							this.viewDetailed(this.growarea, idx);
						}
					});
				})
				.catch((e) => {
					console.log(e);
				});
		},
		archiveBatch(bat, arr, k){
			console.log(`Archiving batch ${JSON.stringify(bat)}`);
			this.batchUpdate = true;
			const body = {
				id: bat._id,
				farm: this.selectedFarm
			}
			axios
				.put(`/${this.selectedFarm}/plantbatch/archive`, body)
				.then((response) => {
					console.log(response.data);
					axios.get(`/${this.farm}/plantbatch/${bat._id}`, { })
					.then((response) => {
						arr[k] = response.data;
						this.$nextTick(() => { this.batchUpdate = false; });
					})
					.catch((e) => {
						console.log(e);
					});
				})
				.catch((e) => {
					console.log(e);
				});
		},
		undoArchiveBatch(bat, arr, k){
			console.log(`Undo archiving batch ${JSON.stringify(bat)}`);
			this.batchUpdate = true;
			const body = {
				id: bat._id,
				farm: this.selectedFarm
			}
			axios
				.put(`${this.selectedFarm}/plantbatch/unarchive`, body)
				.then((response) => {
					console.log(response.data);					
					axios.get(`${this.selectedFarm}/plantbatch/${bat._id}`, { })
					.then((response) => {
						arr[k] = response.data;
						this.$nextTick(() => { this.batchUpdate = false; });
					})
					.catch((e) => {
						console.log(e);
					});
				})
				.catch((e) => {
					console.log(e);
				});
		},
		updateEndDate(){
			if(this.createform.startdate != undefined){
				let ndays = parseInt(this.createform.ndays);
				this.createform.enddate = moment(this.createform.startdate).add(ndays, "d").format("YYYY-MM-DD");
			}
		},
		submitUpdate(action){
			if(Object.keys(this.updateBatches[action]).length === 0){
				console.log("ERROR: No batches selected");
				this.updateError = "ERROR: No batches selected";
				return 1;
			}

			// Validate quantity
			let updateArr = [];
			let sumQty = 0;
			const rCap = this.growareas[this.selectedZone].find(item => item.growarea == this.selectedGA.gaId).capacity;
			const curCap = this.batches[this.selectedGA.gaId] != undefined ? this.batches[this.selectedGA.gaId].qtySum : 0;
			let validity = true;
			for(const bat in this.updateBatches[action]){
				const batch = this.updateBatches[action][bat];
				if(batch.quantity > batch.max_quantity || batch.quantity < (batch.max_quantity == 0 ? 0 : 1)){
					batch.quantity = Math.min(batch.quantity, batch.max_quantity);
					batch.quantity = Math.max(1, batch.quantity);
					validity = false;
					continue;
				}
				
				if(batch.update){
					sumQty += batch.quantity;
					updateArr.push({id: bat, quantity: batch.quantity});
				}
			}
			// If it is a start update, check if update fits into current growarea
			if(action == "start"){
				if(sumQty > (rCap - curCap)){
					validity = false;
				}
			}

			if(!validity){
				console.log("ERROR: batch update quantity exceeded bounds");
				this.updateError = "ERROR: batch update quantity exceeded bounds";
				return 1;
			}

			for(const bat in updateArr){
				const body = {
					batId: updateArr[bat].id,
					qty: updateArr[bat].quantity
				}
				axios
					.put(`${this.selectedFarm}/plantbatches/${action}`, body)
					.then(() => {
						this.dataReady = false;
						this.updateBatches = {start: [], end: []};
						this.selectedGA = {gaId: undefined, idx: undefined};
						this.updateError = "";
						this.$bvModal.hide("detailed-view");
						this.getGrowAreas(true);
						if(this.growarea != undefined){
							this.$router.go(-1);
						}
					})
					.catch((e) => {
						console.log(e);
					});
			}
		},
		updateBatchselect(evt, action, bId){
			if(evt){
				this.updateBatches[action][bId].update = true;
			}
			else{
				this.updateBatches[action][bId].update = false;
			}
			console.log(this.updateBatches[action][bId]);
		},
		viewDetailed(gaId, idx){
			if(this.growarea != undefined){
				if(this.growarea != gaId) {
					this.$router.replace({
						name: "crops",
						params: {farm: this.$route.params.farm},
						query: {zone: this.selectedZone}});
				}
			}

			this.updateBatches = {start: [], end: []};
			this.selectedGA = {gaId: gaId, idx: idx};
			for(var sbat in this.batches[gaId].toStartBatches){
				const batch = this.batches[gaId].toStartBatches[sbat];
				this.updateBatches.start[batch._id] = {
					quantity: batch.quantity,
					max_quantity: batch.quantity,
					update: false
				};
			}
			for(var ebat in this.batches[gaId].toEndBatches){
				const batch = this.batches[gaId].toEndBatches[ebat];
				this.updateBatches.end[batch._id] = {
					quantity: batch.quantity,
					max_quantity: batch.quantity,
					update: false
				};
			}
			this.$bvModal.show("detailed-view");
		},
		addBatchform(){
			this.createform.batches.push({variant: "", quantity: 1});
		},
		removeBatchform(idx){
			console.log(idx);
			this.createform.batches.splice(idx, 1);
		},
		openBatchform(gaId, stg){
			if(this.growarea != undefined){
				if(this.growarea != gaId) {
					this.$router.replace({
						name: "crops",
						params: {farm: this.$route.params.farm},
						query: {zone: this.selectedZone}});
				}
			}

			// Open create batch form with rack ID populated
			this.createform.growarea = gaId;
			this.createform.stage = stg;
			this.$bvModal.show("batch-creation");
		},
		submitBatch(){
			// Validate batch submission
			// Check the startdate and number of days
			if(this.createform.startdate == undefined){
				console.log("ERROR: Invalid start date");
				this.createError = "ERROR: Invalid start date";
				return 1;
			}
			if(this.createform.ndays <= 0){
				console.log("ERROR: Invalid number of days");
				this.createError = "ERROR: Invalid number of days";
				return 1;
			}
			console.log(this.createform.ndays);

			let formQtySum = 0;
			const rCap = this.growareas[this.selectedZone].find(item => item.growarea == this.createform.growarea).capacity;
			const curCap = this.batches[this.createform.growarea] != undefined ? this.batches[this.createform.growarea].qtySum : 0;

			// Check variant name and quantity
			for(const key in this.createform.batches){
				let variant = this.createform.batches[key].variant.trim();
				let quantity = this.createform.batches[key].quantity;
				if(variant.length == 0){
					console.log("ERROR: Invalid batch variant name");
					this.createError = "ERROR: Invalid batch variant name";
					return 1;
				}
				if(quantity <= 0){
					console.log("ERROR: Invalid batch quantity value");
					this.createError = "ERROR: Invalid batch quantity value";
					return 1;
				}
				formQtySum += this.createform.batches[key].quantity;
			}

			if(formQtySum > (rCap - curCap) && moment(this.createform.startdate) <= moment()){
				// console.log(formQtySum);
				// console.log(rCap);
				// console.log(curCap);
				console.log("ERROR: batch total quantity have exceeded growarea limit");
				this.createError = "ERROR: batch total quantity have exceeded growarea limit";
				return 1;
			}

			axios
				.post(`${this.selectedFarm}/plantbatches/submit`, { ...this.createform, ...{ zone: this.selectedZone } })
				.then((response) => {
					console.log(response.data);
					this.createError = "";
					this.createform = {
						stage: '',
						growarea: "",
						startdate: undefined,
						enddate: undefined,
						ndays: 1,
						batches: [{variant: "", quantity: 1}]
					};
					this.$bvModal.hide("batch-creation");
					this.dataReady = false;
					this.getGrowAreas(true);
				})
				.catch((e) => {
					console.log(e);
				});
		}
	}
}
</script>

<style>
.cell-variant {
	width: 40vw;
}
.cell-qty {
	width: 20vw;
}
.batchcard__footer {
	display: flex;
	margin: 0;
	padding: 0;
}
.batchcard__footer * {
	flex: 1;
	margin: 5px;
}
.batchcard {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  font-size: 16px;
  text-align: left;
  width: 410px;
  margin: 1em;
  border-radius: 5px;

  transition: height 0.25s;
  scrollbar-color: white;
  scrollbar-width: thin;
}
.batchcard__toend {
	color: green;
}
.batchcard__delayed {
	color: red;
}
.batchcard__tostart {
	color: gray;
}
.table__archived {
	background-color: lightgoldenrodyellow;
}
.batchlist {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  list-style-type: none;
  scrollbar-width: none; /* for Firefox */
}
#filters {
  background: rgba(240, 255, 240, 0.5);
}
.submission_footer {
	display: flex;
	justify-content: flex-end;	
	margin-bottom: 10px;
}
</style>
